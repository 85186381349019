import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>Oh noes, that content does not seem to exist. Sorry, please <Link className="inlineLink" to="/">try the front page</Link></p>
  </Layout>
)

export default NotFoundPage
